<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-container>
      <v-tabs v-model="tab" center-active>
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#members">Members Renewal </v-tab>
        <v-tab href="#quoted">Quoted </v-tab>
        <v-tab href="#byagent">By Agent </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="members"><members-renewal /> </v-tab-item>
        <v-tab-item value="quoted"> <cotized /> </v-tab-item>
        <v-tab-item value="byagent"> <by-agent /> </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>
<script>
import Cotized from "./Cotized.vue";
import MembersRenewal from "./MembersRenewal.vue";
import ByAgent from "./ByAgent.vue";
export default {
  components: { Cotized, MembersRenewal, ByAgent },
  data() {
    return {
      tab: "members",
    };
  },
};
</script>
<style lang="scss" scoped>
.logo {
  z-index: 999;
}
</style>
